import request from "@/utils/request";
//报名step1-我已知晓
export const singUpInfoConsentNotice = (data) => {
  return request({
    url: "/foreground/singUpInfo/consentNotice",
    method: "GET",
    data:data
  });
};
//报名step2-课程控制
export const singUpInfoGetTrainingPlanByIdInfo = (data) => {
  return request({
    url: "/foreground/singUpInfo/getTrainingPlanByIdInfo",
    method: "GET",
    data:data
  });
};
//报名step2-课程列表
export const singUpInfoCourseList = (data) => {
  return request({
    url: "/foreground/singUpInfo/courseList",
    method: "GET",
    data:data
  });
};
//报名step2-课程提交（用于在*报名的，选课直接通过）
export const singUpInfoAddSingUp = (data) => {
  return request({
    url: "/foreground/singUpInfo/addSingUp",
    method: "POST",
    data:data
  });
};
//报名step3-提交报名生成支付
export const singUpInfoPayMoney = (data) => {
  return request({
    url: "/foreground/singUpInfo/payMoney",
    method: "POST",
    data:data
  });
};
//报名step3-支付验证
export const singUpInfoValidationPayMoney = (data) => {
  return request({
    url: "/foreground/singUpInfo/validationPayMoney",
    method: "POST",
    data:data,
    noLoading:true
  });
};

export const isUerLogin = () => {
  return request({
    url: "/user/login",
    method: "POST",
  });
};


// 电子发票
export const singUpInfoInvoiceList = (data) => {
  return request({
    url: "/foreground/singUpInfo/invoiceList",
    method: "GET",
    data: data,
  });
};

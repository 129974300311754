<template>
  <div class="container">
    <div class="sign-up-step">
      <div class="sign-up-step-item">
        <div class="value" v-if="stepNum === 1">1</div>
        <div class="value active" v-else>
          <i class="el-icon-success"></i>
        </div>
        <div class="label" :class="{ active: stepNum !== 1 }">报名须知</div>
      </div>
      <div class="sign-up-step-line" :class="{ active: stepNum !== 1 }"></div>
      <div class="sign-up-step-item">
        <div class="value active" v-if="stepNum === 3 || stepNum === 4">
          <i class="el-icon-success"></i>
        </div>
        <div class="value" v-else>2</div>
        <div class="label" :class="{ active: stepNum === 3 || stepNum === 4 }">
          选择课程
        </div>
      </div>
      <div
        class="sign-up-step-line"
        :class="{ active: stepNum === 3 || stepNum === 4 }"
      ></div>
      <div class="sign-up-step-item">
        <div class="value active" v-if="stepNum === 4">
          <i class="el-icon-success"></i>
        </div>
        <div class="value" v-else>3</div>
        <div class="label" :class="{ active: stepNum === 3 || stepNum === 4 }">
          确认付款
        </div>
      </div>
    </div>

    <div class="sign-up-body">
      <div class="sign-up-body-item" v-if="stepNum === 1">
        <div class="sign-up-notice">
          <template v-if="singType === 1 || singType === 2">
            <div class="notice-title" v-if="stepNum === 1">
              {{
                isCurrentYearEnum === 1
                  ? "2024年度继续教育网授报名须知"
                  : "补修年度网授报名须知"
              }}
            </div>
            <div class="notice-title" v-if="stepNum === 3 || stepNum === 4">
              四川省执业药师继续教育报名
            </div>
          </template>
          <div class="notice-body">
            <div v-if="isCurrentYearEnum === 1">
              <p>
                亲爱的学员，欢迎您参加由四川中科信息技术专修学院举办的2024年度执业药师继续教育网络培训。四川中科信息技术专修学院为四川省执业药师协会遴选的合规施教机构之一，为四川省人社厅评定的四川省专业技术人员继续教育基地。为加强继教培训流程管理，提高教学质量，报名前请认真阅读报名须知，注意以下事项：
              </p>
              <p>一、培训形式及时间安排：</p>
              <p>
                （一）2024年度执业药师继续教育网络培训统一采取网络课程培训的形式。
              </p>
              <p>
                （二）时间安排<br />
                报名时间：2024年5月24日早9:00至2025年5月24日下午17:00<br />
                学习时间：2024年5月24日早9:00至2025年6月24日下午17:00<br />
              </p>
              <p>二、基本信息填写</p>
              <p>
                注册个人信息：学员须认真、准确的填写个人信息，如个人信息有误，将影响后期打印学时证明。
              </p>
              <p>三、收费标准与发票</p>
              <p>
                2024年度继续教育费用（非协会会员）：费用为每人200元/人/年，由四川中科信息技术专修学院收取并提供税务部门开具的增值税普票。
              </p>
              <p>四、其他事项</p>
              <p>（一）缴费成功后，请记录下交易流水号或截图界面以便于查询；</p>
              <p style="color: #e3211f">
                （二）因个人原因未能在学习时间内完成课程或报错考试年份，无法退返该年度会费/培训费，请在缴费前确认好您的报名年份与相关信息。
              </p>
              <p>五、咨询电话： 028-85531022</p>
            </div>
            <div v-if="isCurrentYearEnum === 0">
              <p>
                亲爱的学员，欢迎您参加由四川中科信息技术专修学院举办的执业药师继续教育补修学习。四川中科信息技术专修学院为四川省执业药师协会遴选的合规施教机构之一，为四川省人社厅评定的四川省专业技术人员继续教育基地。为加强继教培训流程管理，提高教学质量，报名前请认真阅读报名须知，注意以下事项：
              </p>
              <p>一、培训形式及时间安排：</p>
              <p>
                （一）本次往年度执业药师继续教育补修统一采取网络课程培训的形式。
              </p>
              <p>
                （二）时间安排<br />
                报名时间：2024年5月24日早9:00至2025年5月24日下午17:00<br />
                学习时间：2024年5月24日早9:00至2025年6月24日下午17:00<br />
              </p>
              <p>二、基本信息填写</p>
              <p>
                注册个人信息：学员须认真、准确的填写个人信息，如个人信息有误，将影响后期打印学分证明。
              </p>
              <p>三、收费标准与发票</p>
              <p>
                补修往年继续教育收费标准为210元/人/年，培训发票由四川中科信息技术专修学院为学员提供税务部门开具的增值税普票。
              </p>
              <p>四、其他事项</p>
              <p>（一）缴费成功后，请记录下交易流水号或截图界面以便于查询；</p>
              <p style="color: #e3211f">
                （二）因个人原因未能在学习时间内完成课程或报错考试年份，无法退返该年度会费/培训费，请在缴费前确认好您的报名年份与相关信息。
              </p>
              <p>五、咨询电话： 028-85531022</p>
            </div>
            <div class="check-btn">
              <el-checkbox v-model="noticeSure"
                >我已认真阅读并清楚了解该
                <span style="color: #1677fe">《须知内容》</span></el-checkbox
              >
            </div>
            <div class="check-handler-btn">
              <el-button
                :type="!noticeSure ? 'info' : 'danger'"
                :disabled="!noticeSure"
                @click="noticeBtn"
                >我已确认，前往下一步
              </el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="sign-up-body-item curriculum-body" v-if="stepNum === 2">
        <div class="curriculum-choice">
          <div class="choice-title">继续教育专业课-课程选择</div>
          <el-alert
            :title="
              setting && setting.isXueFeng
                ? `注意：专业科目须选满${setting.min}个学时，方可提交，不能多选或少选；公需科目无需选课，报名成功后在学习界面自动生成学习内容`
                : `注意：专业科目须选满${setting.min}门课程，方可提交，不能多选或少选；公需科目无需选课，报名成功后在学习界面自动生成学习内容`
            "
            type="warning"
            :closable="false"
          ></el-alert>
          <div class="choice-body">
            <div
              class="choice-body-item"
              :class="item.active ? 'choice-body-item-hover' : ''"
              v-for="(item, index) in chooseAlternativeList"
              :key="index"
            >
              <div
                class="choice-img"
                :style="`background-image: url('${JSON.parse(
                  item.videoUrl
                )[0].url.replace('.mp4', '.jpg')}')`"
              >
                <div class="choice-lesson-hours" v-if="setting.isXueFeng">
                  {{ item.credits }}学时
                </div>
              </div>
              <div class="choice-body-bottom">
                <div class="choice-bottom-title" :title="item.courseName">
                  <span class="course-type" v-if="item.courseTypeEnum">
                    {{ "[" + item.courseTypeEnum + "]" }}
                  </span>
                  {{ item.courseName }}
                </div>
                <div class="choice-college" :title="item.unitSpeaker">
                  <svg-icon icon="icon-jiangshi" />
                  <div class="college">{{ item.unitSpeaker }}</div>
                </div>
                <div class="choice-btn">
                  <el-button
                    type="danger"
                    plain
                    size="mini"
                    icon="el-icon-video-play"
                    @click="tryListening(item)"
                  >
                    试听课程
                  </el-button>
                  <el-button
                    @click="chooseClick(index, item)"
                    :type="item.active ? 'info' : 'danger'"
                    class="chose"
                    size="mini"
                    >{{ item.active ? "已选本课程" : "选择课程" }}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="choice-pagination">-->
          <!--            <el-pagination-->
          <!--              :page-sizes="[100, 200, 300, 400]"-->
          <!--              :page-size="100"-->
          <!--              layout="total, sizes, prev, pager, next, jumper"-->
          <!--              :total="400"-->
          <!--            >-->
          <!--            </el-pagination>-->
          <!--          </div>-->
        </div>
        <div class="choice-list isFlexd">
          <div class="list-header">
            <div class="list-title">
              <span class="title-name">{{
                setting && setting.isXueFeng ? "已选学时" : "已选课程"
              }}</span>
              <div class="list-number">
                <span>{{ chooseCredit }}</span
                >/{{ setting.min }}
              </div>
            </div>
            <div class="list-progress">
              <el-progress
                v-if="setting.min"
                :percentage="(chooseCredit / (setting && setting.min)) * 100"
                status="exception"
              ></el-progress>
            </div>
            <div class="list-submit">
              <el-button
                :type="chooseCredit < setting.min ? 'info' : 'danger'"
                :disabled="chooseCredit < setting.min"
                @click="chooseToSubmit"
                size="mini"
                style="width: 100%"
                >确认提交
              </el-button>
            </div>
          </div>
          <div class="list-body">
            <div class="list-title">
              已选择课程<span>（{{ chooseList.length }}门）</span>
            </div>
            <el-scrollbar class="list-box" v-if="chooseList.length > 0">
              <div
                class="list-item"
                v-for="(item, index) in chooseList"
                :key="item.id"
              >
                <div class="list-time" v-if="setting && setting.isXueFeng">
                  <div class="list-time-header">{{ item.credits }}</div>
                  学时
                </div>
                <div class="list-info">
                  <div class="list-info-title">
                    {{ item.courseName }}
                  </div>
                  <div class="list-info-teacher">
                    <svg-icon icon="icon-jiangshi" />
                    {{ item.unitSpeaker }}
                  </div>
                </div>
                <div class="list-info-btn">
                  <el-button
                    type="danger"
                    @click="chooseRemove(index, item)"
                    size="mini"
                    >删除课程
                  </el-button>
                </div>
              </div>
            </el-scrollbar>
            <el-empty description="未选择课程" v-else />
          </div>
        </div>
      </div>

      <div
        class="sign-up-body-item sign-up-information-box"
        v-if="stepNum === 3"
      >
        <div class="sign-up-information">
          <div class="information-title">四川省执业药师继续教育报名</div>
          <div class="information-body">
            <div class="information-from">
              <div class="information-header">
                <div class="information-from-title">发票信息</div>
                <div class="information-from-tip">
                  <div class="from-tip-left">须知内容：</div>
                  <div class="from-tip-right">
                    <p>
                      1.电子发票将在3-5天内推送到系统平台，届时可登录学习平台--“电子发票”窗口下载打印。
                    </p>
                    <p>
                      2.如需报销，请提供准确的单位名称和税号，电子发票一但生成，不能再修改对应信息。
                    </p>
                  </div>
                </div>
              </div>
              <div class="information-from-box">
                <el-form
                  ref="invoiceFrom"
                  :model="invoiceFrom"
                  :rules="invoiceRule"
                  label-width="120px"
                >
                  <el-form-item label="类型">
                    <div
                      class="radio-btn"
                      :class="{ active: invoiceFrom.InvoiceType == 0 }"
                      @click="invoiceClick(0)"
                    >
                      个人
                    </div>
                    <div
                      class="radio-btn"
                      :class="{ active: invoiceFrom.InvoiceType == 1 }"
                      @click="invoiceClick(1)"
                    >
                      单位
                    </div>
                  </el-form-item>

                  <el-form-item
                    label="姓名"
                    prop="Invoice"
                    v-if="invoiceFrom.InvoiceType == 0"
                    :rules="[
                      {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      style="width: 300px"
                      v-model="invoiceFrom.Invoice"
                      placeholder="请输入姓名"
                    />
                  </el-form-item>

                  <el-form-item label="手机号" prop="InvoiceTel">
                    <el-input
                      style="width: 300px"
                      placeholder="请输入联系电话"
                      v-model="invoiceFrom.InvoiceTel"
                    />
                  </el-form-item>
                  <el-form-item label="邮箱" prop="InvoiceEmail">
                    <el-input
                      style="width: 300px"
                      v-model="invoiceFrom.InvoiceEmail"
                      placeholder="请填写邮箱"
                    />
                  </el-form-item>

                  <el-form-item
                    label="工作单位"
                    prop="Invoice"
                    v-if="invoiceFrom.InvoiceType == 1"
                    :rules="[
                      {
                        required: true,
                        message: '请输入工作单位',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      style="width: 300px"
                      placeholder="请输入工作单位"
                      v-model="invoiceFrom.Invoice"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="Unified_credit_code"
                    v-if="invoiceFrom.InvoiceType == 1"
                  >
                    <div style="display: inline-block" slot="label">
                      <span>信用代码</span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="请咨询财务人员，开具抬头为工作单位的必填项"
                        placement="top-start"
                      >
                        <i class="el-icon-warning"></i>
                      </el-tooltip>
                    </div>
                    <el-input
                      style="width: 300px"
                      placeholder="请填写纳税人识别或统一信用代码证"
                      v-model="invoiceFrom.Unified_credit_code"
                    />
                  </el-form-item>
                </el-form>
              </div>
              <div class="information-form-btn">
                <div class="btn-header">
                  <div class="btn-header-left">
                    <el-checkbox v-model="playCheckBox"
                      >已知晓须知内容
                    </el-checkbox>
                  </div>
                  <div class="btn-header-right">
                    <div class="btn-price">
                      总计：<span
                        >￥<em>{{ setInfos.money }}</em></span
                      >
                    </div>
                    <div class="btn-tip">
                      此次报名的费用:{{ setInfos.money }}元
                    </div>
                  </div>
                </div>
                <el-button
                  type="danger"
                  size="mini"
                  class="btn-bottom"
                  @click="playerBtnClick"
                  :disabled="!playCheckBox"
                >
                  点击支付{{ setInfos.year }}年度培训费
                </el-button>
              </div>
            </div>
            <div class="information-list">
              <div class="information-from-title">课程列表</div>
              <el-scrollbar class="information-form-box">
                <div
                  class="information-form-item"
                  v-for="item in setInfos.list"
                  :key="item.id"
                >
                  <div
                    class="information-item-left"
                    :style="`background-image: url('${JSON.parse(
                      item.videoUrl
                    )[0].url.replace('.mp4', '.jpg')}')`"
                  >
                    <div
                      class="lesson-hours"
                      v-if="setting && setting.isXueFeng"
                    >
                      {{ item.credits }}学时
                    </div>
                  </div>
                  <div class="information-item-right">
                    <div class="item-title">
                      {{ item.courseName }}
                    </div>
                    <div class="item-content">
                      <svg-icon icon="icon-jiangshi" />
                      <span>{{ item.unitSpeaker }}</span>
                    </div>
                    <div class="item-btn">
                      <el-button
                        type="danger"
                        icon="el-icon-video-play"
                        plain
                        size="mini"
                        @click="tryListening(item)"
                        >试听课程
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="play-dialog" v-if="zxingShow">
          <div class="play-mask"></div>
          <div class="play-box">
            <div class="play-body">
              <div class="zxing">
                <div class="zxing-img" ref="qrcodes" :title="qrtitle"></div>
                <div class="zxing-tip">
                  <i class="el-icon-warning-outline"></i>
                  <span>请使用微信扫码支付费用</span>
                </div>
              </div>
              <div class="zxing-info">
                <div class="info-body">
                  <div class="zxing-info-item">
                    <div class="item-label">报名信息</div>
                    <div class="item-value">
                      {{ setInfos.year }}年度继续教育网授
                    </div>
                  </div>
                  <div class="zxing-info-item">
                    <div class="item-label">支付费用</div>
                    <div class="item-value">{{ setInfos.money }}元</div>
                  </div>
                </div>
                <el-alert
                  type="warning"
                  :closable="false"
                  style="text-align: left"
                  title="在线支付成功后，24小时内开通课程，请从协会网站首页的”学员登录“版块登录学习。"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sign-up-body-item" v-if="stepNum === 4">
        <div class="sign-end">
          <div class="sign-box" v-if="Successful">
            <img src="@/assets/images/success-img.png" alt="" />
            <div class="sign-tips">恭喜报名成功</div>
            <div class="tips-content">
              学习时间为<br />
              <span>{{ setInfos.startTime }}到{{ setInfos.endTime }}</span>
              <br />
              请合理安排您的学习时间
            </div>
            <div class="tips-button">
              <el-button type="danger" size="mini" @click="toSpecialized"
                >报名成功，前往学习
              </el-button>
            </div>
          </div>
          <div class="sign-box" v-else>
            <img src="@/assets/images/error-img.png" alt="" />
            <div class="sign-tips">报名失败</div>
            <div class="tips-content">失败原因</div>
            <div class="tips-button">
              <el-button type="danger" size="mini">重新报名</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="tryToSeeVisible"
      :title="tryToSeeTitle"
      :destroy-on-close="true"
      :visible.sync="tryToSeeVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="player-box">
        <video-player
          :is-to-see-time="180"
          :if-drag-seek="true"
          :autoplay="true"
          :is-to-see="true"
          v-if="tryToSeeVisible"
          :player-list="tryToSeePlayer"
        />
      </div>
    </el-dialog>
    <el-dialog
      :bodyStyle="{ padding: '0px' }"
      :show-close="false"
      :visible="centerDialogVisible"
      width="50%"
      custom-class="tipsClass"
      center
    >
      <template #title></template>
      <sinup-tip @closeDialog="signTip"></sinup-tip>
    </el-dialog>
  </div>
</template>
<script>
import SvgIcon from "@/components/svgIcon.vue";
import VideoPlayer from "@/components/videoPlayer.vue";
import sinupTip from "@/components/sinupTip.vue";
import * as storage from "@/utils/storage";
import QRCode from "qrcodejs2";
import {
  singUpInfoAddSingUp,
  singUpInfoConsentNotice,
  singUpInfoCourseList,
  singUpInfoGetTrainingPlanByIdInfo,
  singUpInfoPayMoney,
  singUpInfoValidationPayMoney,
} from "@/api/registerController";

export default {
  components: { VideoPlayer, SvgIcon, sinupTip },
  data() {
    return {
      ValidationPay: null,
      Successful: false,
      setInfos: {},
      qrtitle: "",
      centerDialogVisible: false,
      setting: {},
      tryToSeeTitle: "试看视频",
      tryToSeeVisible: false,
      tryToSeePlayer: "",
      chooseList: [],
      chooseAlternativeList: [],
      stepNum: 1,
      noticeSure: false,
      playCheckBox: false,
      zxingImg: "",
      zxingShow: false,
      invoiceFrom: {
        InvoiceType: 0,
        Invoice: undefined,
        InvoiceTel: undefined,
        InvoiceEmail: undefined,
        Unified_credit_code: undefined,
        employer: undefined,
      },
      invoiceRule: {
        InvoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],

        InvoiceEmail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern:
              /^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
        InvoiceTel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        Unified_credit_code: [
          { required: true, message: "请输入信用代码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    chooseCredit() {
      if (this.setting && this.setting.isXueFeng) {
        if (this.chooseList.length > 0) {
          let count = 0;
          this.chooseList.map((item) => {
            count += Number(item.credits);
          });
          return count;
        } else {
          return 0;
        }
      } else {
        if (this.chooseList.length > 0) {
          let count = 0;
          this.chooseList.map(() => {
            count += Number(1);
          });
          return count;
        } else {
          return 0;
        }
      }
    },
    singType() {
      return storage.getStore("singType");
    },
    yearId() {
      return storage.getStore("yearId");
    },
    isCurrentYearEnum() {
      return storage.getStore("isCurrentYearEnum");
    },
  },
  activated() {
    const that = this;
    if (this.stepNum === 2) {
      this.centerDialogVisible = true;
      // 步骤2 初始数据获取
      singUpInfoGetTrainingPlanByIdInfo({
        trainingPlanId: that.yearId,
      }).then((_res) => {
        that.setting = _res.data;
      });
      singUpInfoCourseList({ trainingPlanId: that.yearId }).then((_res) => {
        that.chooseAlternativeList = _res.data;
      });
    }
  },
  watch: {
    stepNum: function (newV) {
      const that = this;
      if (newV === 2) {
        // 步骤2 初始数据获取
        singUpInfoGetTrainingPlanByIdInfo({
          trainingPlanId: that.yearId,
        }).then((_res) => {
          that.setting = _res.data;
        });
        singUpInfoCourseList({ trainingPlanId: that.yearId }).then((_res) => {
          that.chooseAlternativeList = _res.data;
        });
      }
    },
  },
  methods: {
    // 报名成功 跳转学习页面
    toSpecialized() {
      this.keepAliveNavigation("/specialized");
    },
    //signTip 弹窗提示
    signTip() {
      this.centerDialogVisible = false;
    },
    //第一步 下一页
    noticeBtn() {
      var that = this;
      if (!that.noticeSure) {
        that.$message({
          message: "请认真阅读并同意《须知内容》",
          type: "warning",
        });
      } else {
        singUpInfoConsentNotice({
          trainingPlanId: that.yearId,
          singType: that.singType,
        }).then(async () => {
          this.stepNum++;
        });
      }
    },
    // 课程选择提交
    chooseToSubmit() {
      var that = this;
      if (that.chooseCredit < that.setting.min) {
        that.$message({
          message: `您选择的学时未达到${that.setting.min}学时，不能提交`,
          type: "warning",
        });
        return false;
      }
      if (that.chooseCredit > that.setting.max) {
        that.$message({
          message: `选择课程的学时超过了${that.setting.max}学时`,
          type: "warning",
        });
        return false;
      }

      //提交
      /*
       * idList @选课ID列表
       * trainingId
       * invoiceTypeEnum @发票抬头类型:0、姓名;1、工作单位
       * isInvoiceEnum @是否需要发票:1、是;0、否
       * invoice @发票抬头
       * invoiceTel @发票接收电话
       * invoicemail @发票邮箱
       * unifiedcreditcode @纳税人识别或统一信用代码证
       * isH5Enum @是否H5支付:0否。1是
       * */
      var ids = JSON.parse(JSON.stringify(that.chooseList)).map((item) => {
        return item.id;
      });
      singUpInfoAddSingUp({
        idList: ids,
        trainingId: that.yearId,
        invoiceTypeEnum: 0,
        isInvoiceEnum: 0,
        isH5Enum: 0,
      }).then((res) => {
        this.setInfos = res.data;
        if (res.data.state === 1) {
          this.stepNum = 3;
        } else if (res.data.state === 2) {
          this.Successful = true;
          this.stepNum = 4;
        }
      });
    },
    // 试听课程
    tryListening(data) {
      this.tryToSeePlayer = JSON.parse(data.videoUrl);
      this.tryToSeeTitle = data.courseName + "试听课程";
      this.tryToSeeVisible = true;
    },
    // 选择课程
    chooseClick(_index, data) {
      if (this.setting && this.setting.isXueFeng) {
        // 选择学时
        if (
          !data.active &&
          Number(this.chooseCredit) + Number(data.credits) <= this.setting.max
        ) {
          if (this.chooseList.findIndex((item) => item.id === data.id) === -1) {
            data["active"] = true;
            this.chooseList.push(data);
          } else {
            data["active"] = false;
            this.chooseList = this.chooseList.filter(
              (item) => data.id != item.id
            );
          }
        } else if (data.active) {
          data["active"] = false;
          this.chooseList = this.chooseList.filter(
            (item) => data.id != item.id
          );
        } else {
          this.$message({
            type: "warning",
            message: `您添加该课程后总学时超过${this.setting.max}个学时，请重新选择课程！`,
          });
        }
      } else {
        //选择课程数
        if (
          !data.active &&
          Number(this.chooseCredit) + Number(1) <= this.setting.max
        ) {
          if (this.chooseList.findIndex((item) => item.id === data.id) === -1) {
            data["active"] = true;
            this.chooseList.push(data);
          } else {
            data["active"] = false;
            this.chooseList = this.chooseList.filter(
              (item) => data.id != item.id
            );
          }
        } else if (data.active) {
          data["active"] = false;
          this.chooseList = this.chooseList.filter(
            (item) => data.id != item.id
          );
        } else {
          this.$message({
            type: "warning",
            message: `您添加该课程后总课程超过${this.setting.max}门，请重新选择课程！`,
          });
        }
      }
    },
    // 移除选择的课程
    chooseRemove(index, item) {
      item.active = false;
      this.chooseList.splice(index, 1);
    },
    // 选择发票信息
    invoiceClick(type) {
      this.invoiceFrom.InvoiceType = type;
      if (type == 0) {
        this.invoiceFrom.employer = undefined;
        this.invoiceFrom.Unified_credit_code = undefined;
      } else {
        this.invoiceFrom.Invoice = undefined;
      }
      this.$refs.invoiceFrom.clearValidate();
    },
    // 支付报名费用
    playerBtnClick() {
      const that = this;
      that.$refs.invoiceFrom.validate((valid) => {
        if (valid) {
          /*
           * idList @选课ID列表
           * trainingId
           * invoiceTypeEnum @发票抬头类型:0、姓名;1、工作单位
           * isInvoiceEnum @是否需要发票:1、是;0、否
           * invoice @发票抬头
           * invoiceTel @发票接收电话
           * invoicemail @发票邮箱
           * unifiedcreditcode @纳税人识别或统一信用代码证
           * isH5Enum @是否H5支付:0否。1是
           * */

          // invoiceFrom: {
          //    InvoiceType: 0,
          //     Invoice: undefined,
          //     InvoiceTel: undefined,
          //     InvoiceEmail: undefined,
          //     Unified_credit_code: undefined,
          //     employer: undefined,
          // },
          var ids = JSON.parse(JSON.stringify(that.chooseList)).map((item) => {
            return item.id;
          });
          singUpInfoPayMoney({
            idList: ids,
            trainingId: that.yearId,
            invoiceTypeEnum: that.invoiceFrom.InvoiceType,
            invoice: that.invoiceFrom.Invoice,
            invoiceTel: that.invoiceFrom.InvoiceTel,
            invoicemail: that.invoiceFrom.InvoiceEmail,
            unifiedCreditCode: that.invoiceFrom.Unified_credit_code,
            isInvoiceEnum: 1,
            isH5Enum: 0,
          }).then(async (res) => {
            that.zxingShow = true;
            this.$nextTick(() => {
              new QRCode(this.$refs.qrcodes, {
                text: res.data.codeUrl, // 需要转换为二维码的内容
                width: 180,
                height: 180,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
              document.getElementsByClassName("zxing-img")[0].title =
                "支付二维码";
            });

            this.ValidationPay = setInterval(async () => {
              await singUpInfoValidationPayMoney({ id: res.data.id }).then(
                (_res) => {
                  if (_res.data) {
                    clearInterval(this.ValidationPay);
                    this.Successful = true;
                    this.stepNum = 4;
                  }
                }
              );
            }, 3000);
          });
        }
      });
    },
    // 关闭在线支付页面
    zxingClose() {
      this.zxingImg = "";
      this.zxingShow = false;
    },
  },
  destroyed() {
    clearInterval(this.ValidationPay);
  },
};
</script>
<style scoped lang="scss">
/deep/ .tipsClass .el-dialog__header {
  display: none;
}

/deep/ .tipsClass .el-dialog__body {
  padding: 0px;
}

.isFlexd {
  position: sticky;
  top: 0;
}

.container {
  padding-top: 24px;
}

.sign-up-step {
  background-color: #ffffff;
  padding: 28px 64px;
  margin-bottom: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sign-up-step-item {
    font-size: 20px;
    color: #818496;
    display: flex;
    align-items: center;

    .value {
      font-weight: 500;
      width: 24px;
      height: 24px;
      border-radius: 100px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #818496;
      background: rgba(129, 132, 150, 0.1);

      &.active {
        color: #ba2926;

        i {
          font-size: 24px;
        }
      }
    }

    .label.active {
      color: #ba2926;
    }
  }

  .sign-up-step-line {
    width: 360px;
    border-bottom: 2px dashed #818496;

    &.active {
      border-bottom: 2px solid #ba2926;
    }
  }
}

.sign-up-body-item {
  padding-bottom: 32px;
}

.sign-up-notice {
  padding: 32px;
  background-color: #ffffff;
  border-radius: 4px;

  .notice-title {
    font-size: 24px;
    color: #21252e;
    text-align: center;
    font-weight: 500;
  }

  .notice-body {
    padding: 0 64px;
    font-size: 16px;
    color: #505363;
    line-height: 1.8;
    font-weight: 500;

    .check-btn {
      font-size: 14px;

      ::v-deep .is-checked .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #e3211f;
        border-color: #e3211f;
      }

      ::v-deep
        .is-checked
        .el-checkbox__input.is-checked
        + .el-checkbox__label {
        color: #e3211f;
      }
    }

    .check-handler-btn {
      margin-top: 20px;
      text-align: center;

      ::v-deep .el-button {
        padding: 12px 57px;
      }
    }
  }
}

.curriculum-body {
  width: 100%;
  display: flex;

  .curriculum-choice {
    width: calc(100% - 320px);
    margin-right: 20px;
    height: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 24px;

    .choice-title {
      font-size: 24px;
      font-weight: 500;
      color: #21252e;
      margin-bottom: 16px;
      text-align: center;
    }

    .choice-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;

      &:after {
        content: "";
        clear: both;
        display: table;
      }
      .choice-body-item-hover {
        border: 1px solid #d47b79 !important;
        background: rgba(186, 41, 38, 0.05);
      }
      .choice-body-item {
        cursor: pointer;
        float: left;
        margin-right: 10px;
        box-sizing: border-box;
        padding: 12px;
        margin-top: 10px;
        width: 100%;
        border: 1px solid #f2f2f2;
        border-radius: 6px;

        &:hover {
          border-color: #d47b79;
          background: rgba(186, 41, 38, 0.05);
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        .choice-img {
          width: 100%;
          height: 150px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;

          .choice-lesson-hours {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #549a54;
            padding: 4px 8px;
            border-radius: 2px 0 6px 0;
            font-size: 12px;
            color: #ffffff;
          }
        }

        .choice-body-bottom {
          margin-top: 10px;

          .choice-bottom-title {
            font-size: 14px;
            color: #21252e;
            font-weight: 500;
            margin-bottom: 5px;
            min-height: 40px;
            .course-type {
              color: #ba2926;
              font-weight: bold;
            }
          }

          .choice-college {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #818496;
            margin: 12px 0;
            width: 251.64px;
            .college {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .choice-btn {
          ::v-deep .chose {
            width: 154px;
          }
        }
      }
    }

    .choice-pagination {
      text-align: right;
      margin-top: 10px;
    }
  }

  .choice-list {
    width: 300px;
    height: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    box-sizing: border-box;

    .list-header {
      padding: 16px 12px;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 16px;

      .list-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-name {
          font-size: 16px;
          color: #21252e;
          font-weight: 500;
        }

        .list-number {
          font-weight: 500;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.7);

          span {
            color: #ba2926;
          }
        }
      }

      .list-progress {
        ::v-deep .el-progress .el-progress__text {
          display: none;
        }

        ::v-deep .el-progress-bar {
          padding-right: 0;
        }
      }

      .list-submit {
        margin-top: 10px;
      }
    }

    .list-body {
      height: calc(100% - 138px);

      .list-title {
        margin: 0 16px 10px;
        display: flex;
        align-items: flex-end;
        color: #21252e;
        font-size: 14px;

        span {
          color: #818496;
          font-size: 12px;
        }
      }

      .list-box {
        width: 100%;
        height: calc(100% - 30px);
        max-height: 400px;
        overflow-y: auto;
        padding-bottom: 20px;
        padding-right: 0;
        .list-item {
          margin: 0 12px 12px;
          position: relative;
          display: flex;
          align-items: flex-start;
          padding: 12px 16px;
          box-sizing: border-box;
          background-color: #f4f6f8;
          border-radius: 4px;
          cursor: pointer;

          .list-time {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #549a54;
            border-radius: 4px;
            color: #ffffff;
            font-size: 12px;
            width: 38px;
            height: 42px;

            .list-time-header {
              font-size: 14px;
            }
          }

          .list-info {
            width: calc(100% - 46px);
            margin-left: 8px;

            .list-info-title {
              font-size: 14px;
              color: #3d3d3d;
              word-break: break-all;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .list-info-teacher {
              margin-top: 4px;
              font-size: 12px;
              color: #818496;
            }
          }

          .list-info-btn {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 6px 12px;
            box-sizing: border-box;
            width: 100%;
            z-index: 1;

            ::v-deep .el-button {
              width: 100%;
            }
          }

          &:hover {
            .list-info-btn {
              display: block;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.sign-up-information-box {
  width: 100%;
  height: 745px;

  .sign-up-information {
    padding: 32px;
    background-color: #ffffff;
    border-radius: 4px;
    height: 100%;
    box-sizing: border-box;

    .information-title {
      padding-bottom: 16px;
      color: #21252e;
      border-bottom: 1px solid #ebebeb;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 16px;
    }

    .information-body {
      display: flex;
      width: 100%;
      height: calc(100% - 48px);

      .information-from {
        width: calc(100% - 340px);
        height: 100%;
        padding-right: 16px;
        box-sizing: border-box;
        border-right: 1px solid #ebebeb;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .information-from-title {
          padding-left: 20px;
          position: relative;
          font-size: 18px;
          color: #3d3d3d;

          &:after {
            position: absolute;
            left: 4px;
            top: 7px;
            content: "";
            width: 10px;
            height: 10px;
            background: #ba2926;
          }
        }

        .information-from-tip {
          margin-top: 8px;
          background: rgba(215, 25, 23, 0.05);
          display: flex;
          padding: 10px 69px;
          align-items: flex-start;
          color: #d71917;
          font-size: 14px;
          font-weight: 500;

          .from-tip-left {
            width: 70px;
            line-height: 1.8;
          }

          .from-tip-right p {
            margin: 0;
            line-height: 1.8;
          }
        }

        .information-form-btn {
          text-align: center;

          .btn-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .btn-header-left {
              font-size: 14px;

              ::v-deep
                .is-checked
                .el-checkbox__input.is-checked
                .el-checkbox__inner {
                background-color: #e3211f;
                border-color: #e3211f;
              }

              ::v-deep
                .is-checked
                .el-checkbox__input.is-checked
                + .el-checkbox__label {
                color: #e3211f;
              }
            }

            .btn-header-right {
              text-align: right;

              .btn-price {
                color: #505363;
                font-size: 14px;

                span {
                  color: #ba2926;
                  font-size: 16px;
                  font-weight: 500;

                  em {
                    font-size: 24px;
                    font-style: normal;
                  }
                }
              }
            }
          }

          .btn-bottom {
            display: inline-block;
            margin: 24px;
            padding: 8px 50px;
            border-radius: 4px;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }

      .information-list {
        width: 340px;

        .information-from-title {
          padding-left: 20px;
          position: relative;
          font-size: 18px;
          margin-left: 10px;
          color: #3d3d3d;

          &:after {
            position: absolute;
            left: 4px;
            top: 7px;
            content: "";
            width: 10px;
            height: 10px;
            background: #ba2926;
          }
        }
      }
    }
  }
}

.information-from-box {
  margin: 8px 0;

  ::v-deep .el-form-item__label {
    text-align: left;
    font-size: 14px;
    color: #21252e;

    .el-icon-warning {
      color: #b5bcc9;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.information-form-box {
  width: 100%;
  height: calc(100% - 30px);
  padding-top: 10px;
  box-sizing: border-box;
  overflow: hidden;

  .information-form-item {
    margin: 0 10px 10px;
    width: calc(100% - 20px);
    display: flex;
    align-items: flex-start;
    padding: 10px;
    box-sizing: border-box;
    background: #f4f6f8;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    .information-item-left {
      width: 100px;
      height: 90px;
      border-radius: 4px;
      position: relative;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .lesson-hours {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #549a54;
        padding: 4px 8px;
        border-radius: 2px 0 6px 0;
        font-size: 12px;
        color: #ffffff;
      }
    }

    .information-item-right {
      width: calc(100% - 105px);
      margin-left: 5px;

      .item-title {
        color: #21252e;
        font-size: 14px;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .item-content {
        margin-top: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #818496;
        font-size: 12px;
      }

      .item-btn {
        margin-top: 5px;

        button {
          width: 100%;
        }
      }
    }
  }
}

.radio-btn {
  display: inline-block;
  padding: 4px 12px;
  font-size: 14px;
  border: 1px solid #b5bcc9;
  border-radius: 4px;
  line-height: 1.5;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    color: #ffffff;
    background-color: #ba2926;
    border-color: rgba(186, 41, 38, 0.02);
  }

  &.active {
    color: #ffffff;
    background-color: #ba2926;
    border-color: rgba(186, 41, 38, 0.02);
  }
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.play-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .play-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .play-box {
    text-align: center;
    position: relative;
    z-index: 2;

    .play-body {
      width: 500px;
      padding: 24px;
      background-color: #ffffff;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .zxing {
      width: 200px;
      padding: 10px;

      .zxing-img {
        width: 180px;
        height: 180px;
        background: linear-gradient(to left, #d8d8d8, #d8d8d8) left top
            no-repeat,
          linear-gradient(to bottom, #d8d8d8, #d8d8d8) left top no-repeat,
          linear-gradient(to left, #d8d8d8, #d8d8d8) right top no-repeat,
          linear-gradient(to bottom, #d8d8d8, #d8d8d8) right top no-repeat,
          linear-gradient(to left, #d8d8d8, #d8d8d8) left bottom no-repeat,
          linear-gradient(to bottom, #d8d8d8, #d8d8d8) left bottom no-repeat,
          linear-gradient(to left, #d8d8d8, #d8d8d8) right bottom no-repeat,
          linear-gradient(to left, #d8d8d8, #d8d8d8) right bottom no-repeat;
        background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
        padding: 10px;
        position: relative;

        /deep/ img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .zxing-tip {
        margin-top: 10px;
        color: #818496;
        font-size: 12px;
      }
    }

    .zxing-info {
      width: calc(100% - 220px);
      height: 200px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .zxing-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }

    .el-icon-error {
      font-size: 30px;
      margin-top: 20px;
      color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
}

.sign-end {
  width: 100%;
  height: 424px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  .sign-box {
    text-align: center;

    img {
      width: 100px;
      height: 100px;
    }

    .sign-tips {
      margin-top: 5px;
      font-size: 24px;
      color: #3d3d3d;
      font-weight: 500;
    }

    .tips-content {
      font-size: 18px;
      margin-top: 5px;
      color: #818496;
      line-height: 1.5;

      span {
        color: #1677fe;
      }
    }

    .tips-button {
      margin-top: 20px;
    }
  }
}

.player-box {
  height: 400px;
}
</style>

<template>
  <div class="Tip">
    <div class="tipDialog">温馨提示</div>
    <div class="body">
      <p>欢迎进入专业课学习，根据《执业药师继续教育暂行规定》、《专业技术人员继续教育规定》（人社部令第25号）、《执业药师注册管理办法》（国药监人【2021】36号）的相关规定：执业药师应当自取得执业药师职业资格证书的次年起开始参加继续教育，执业药师每年参加的继续教育不少于90学时。执业药师继续教育内容包括公需科目和专业科目。其中，专业科目学时一般不少于总学时的三分之二，即专业课学习须学满60学时</p>
      <!--      <p>（2）学习：学员需每日通过阅读文章或观看视频并通过随堂测试，可以获得相应的有效学习时长。</p>-->
      <!--      <p>阅读文章按每650字计一分钟学习时长，文章最上方的学习进度条已满视为完成阅读。</p>-->
      <!--      <p>视频学习按视频实际时长计为学习时长。</p>-->
      <!--      <p>建议使用googleChrome浏览器、360浏览器极速模式，学习时不可刷新或关闭页面，否则将不记录阅读学时。</p>-->
    </div>
    <div class="Acenter m-t-20" style="text-align: center">
      <el-divider></el-divider>
      <el-button type="danger" class="buttom_on" @click="$emit('closeDialog')">我知道了</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexTip',
  props:['classType']
}
</script>

<style lang="scss" scoped>
.tipDialog{
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/images/tiptop.png");
}
.Tip{
  width: 100%;
  .tipDialog{
    font-weight: bold;
    font-size: 40px;
    color:#ffffff;
    text-align: center;
    line-height: 160px;
  }
  .body{
    padding: 20px;
    p{
      margin-top: 15px;
      line-height: 1.5;
      font-size: 16px;
      letter-spacing: 2px;
      text-indent: 2em;
    }
  }
  .el-divider--horizontal{
    margin: 10px 0;
  }
  .buttom_on{
    margin-bottom: 20px;
  }
}
.el-button--danger{
  background-color: #ce0907;
  border-color: #ce0907;
}
@media screen and (min-width: 320px) and (max-width: 720px) {
  .Tip {
    .tipDialog{
      font-size: 24px;
      height: 120px;
      line-height: 95px;
    }
    .body {
      padding: 0 20px;
      p {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
</style>
